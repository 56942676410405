<template>
  <div class="wrapper">
    <div class="content">
      <div class="heading-block">
        <img
          src="https://res.cloudinary.com/dspi5zyaa/image/upload/v1613657966/eh-platform/logos/eh-logo-green.png"
          alt="EntwicklerHeld Logo"
          class="heading-logo"
        />
        <h1>Willkommen im Company Hub</h1>
        <h2 class="d-block d-xl-none mobile-subheading"
          >Der EntwicklerHeld Company Hub ist Ihr Unternehmenszugang zur EntwicklerHeld-Plattform. Hier finden Sie Ihr
          Unternehmensprofil, Ihre Challenges, Statistiken und interessierte Talente.</h2
        >
      </div>
      <div class="stripe-wrapper">
        <div class="video-wrapper">
          <iframe
            class="yt-video"
            src="https://www.youtube.com/embed/XmbcNMIRKPg"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <div class="stripe-content-wrapper col-lg-6 col-xl-6 col-md-12 d-none d-xl-block">
            <span class="box-content-heading">Was kann ich hier machen?</span>
            <p>
              Der EntwicklerHeld Company Hub ist Ihr Unternehmenszugang zur EntwicklerHeld-Plattform. Hier finden Sie Ihr
              Unternehmensprofil, Ihre Challenges, Statistiken und interessierte Talente.
            </p>
          </div>
        </div>

        <img src="@/assets/welcome-background-box-large.svg" class="background-box d-block d-xl-none" />
        <img src="@/assets/welcome-background-box.svg" class="background-box d-none d-xl-block" />
      </div>
      <div v-if="!isRegistrationCodePresent" class="cta-block">
        <button class="main-cta-button" @click="login">Anmelden</button>
        <p class="register-text">
          Noch nicht dabei?
          <router-link :to="{ name: getRegistrationLink() }" class="nav-item"> Hier registrieren</router-link>
        </p>
      </div>
      <div v-if="isRegistrationCodePresent" class="cta-block">
        <button class="main-cta-button" @click="navigateToRegistration">Registrieren</button>
        <p class="register-text">
          Schon dabei?
          <a href="#" class="nav-item" @click="login"> Hier anmelden</a>
        </p>
      </div>
    </div>

    <svg
      class="top-wave"
      width="1140"
      height="177"
      viewBox="0 0 1140 177"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M241.725 135.691L91.4325 158.3C43.3002 165.54 0 128.266 0 79.5926C0 35.6348 35.6348 7.46465e-06 79.5925 1.66728e-05L1140 0.000238803V175.125L1027.13 144.842L1002.45 138.208C932.619 119.439 897.703 110.054 861.92 109.416C826.137 108.778 790.909 116.912 720.452 133.18L594.144 162.343C551.762 172.128 530.571 177.021 509.014 176.99C487.457 176.96 466.28 172.007 423.926 162.101L385.348 153.079L318.384 138.297L318.384 138.296C314.341 137.404 312.319 136.958 310.303 136.559C290.413 132.622 270.017 131.929 249.905 134.506C247.867 134.767 245.82 135.075 241.727 135.691L241.725 135.691Z"
        fill="#F1F1F4"
      />
      <path
        d="M241.725 135.691L91.4325 158.3C43.3002 165.54 0 128.266 0 79.5926C0 35.6348 35.6348 7.46465e-06 79.5925 1.66728e-05L1140 0.000238803V175.125L1027.13 144.842L1002.45 138.208C932.619 119.439 897.703 110.054 861.92 109.416C826.137 108.778 790.909 116.912 720.452 133.18L594.144 162.343C551.762 172.128 530.571 177.021 509.014 176.99C487.457 176.96 466.28 172.007 423.926 162.101L385.348 153.079L318.384 138.297L318.384 138.296C314.341 137.404 312.319 136.958 310.303 136.559C290.413 132.622 270.017 131.929 249.905 134.506C247.867 134.767 245.82 135.075 241.727 135.691L241.725 135.691Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="51.5996"
          y1="84.8949"
          x2="994.34"
          y2="31.3886"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
import { services } from "@/main.js";
import { eventBus } from "@/eventbus";

import { navigateTo } from "@/utils/routing";
import { isRegistrationCodePresent } from "@/utils/registrationCode";

export default {
  components: {
    // Register your components here
  },
  props: {},
  data() {
    return {
      isAuthenticated: services.authenticationService.isAuthenticated(),
    };
  },
  computed: {
    isRegistrationCodePresent: function() {
      return isRegistrationCodePresent(this.$route.query);
    },
  },
  mounted() {
    eventBus.$on("userLoggedIn", () => {
      this.isAuthenticated = services.authenticationService.isAuthenticated();
    });
  },
  beforeDestroy() {
    eventBus.$off("userLoggedIn");
  },
  methods: {
    login: function() {
      services.authenticationService.login({ fullPath: "after_login_redirect" });
    },
    getRegistrationLink() {
      if (isRegistrationCodePresent(this.$route.query)) {
        return "register-with-code";
      }
      return "register";
    },
    navigateToRegistration() {
      navigateTo(this.$router, { name: "register-with-code" });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 35px;
}

.wrapper {
  width: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.register-div {
  font-size: 0.8rem;
}

.register-link {
  text-decoration: underline !important;
}

.register-link:hover {
  cursor: pointer;
}

.top-wave {
  position: absolute;
  top: -80px;
  right: -20px;
  z-index: 0;
  max-width: 80%;
}

.content {
  z-index: 1;
  padding: 15px 55px;
  width: 100%;
  max-width: 1920px;
}

.heading-logo {
  width: 150px;
}

.heading-block {
  margin: 3rem 0 6rem 0;
  padding: 0 2.5% 0 2.5%;
}

.background-box {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.video-wrapper {
  /* Keeps the aspect ratio of the video - base value for 100% and 16:9 is 56.25% */
  padding-bottom: 36%;
  width: 64%;
  position: relative;
  z-index: 3;
  margin-top: -1.7%;
}

.stripe-content-wrapper > p {
  margin: 0;
}

.stripe-content-wrapper {
  /* width: 50%;*/
  position: absolute;
  /*left: calc(64% + 1rem);*/
  left: calc(100% + 2rem);
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(#fff, #f1f1f1);
  z-index: 3;
  padding: 20px;
  border-radius: 15px;
}

.yt-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  filter: drop-shadow(0px 0px 37px rgba(0, 0, 0, 0.17));
}

.box-content-heading {
  font-weight: bold;
  text-transform: uppercase;
  color: #609596;
}

.stripe-wrapper {
  position: relative;
  width: 100%;
  margin: 4rem 0;
  padding: 2.5%;
  padding-top: 1%;
}

.main-cta-button:hover {
  background: #52847b;
}

.main-cta-button {
  transition: background-color linear 0.2s;
  background: #639f94;
  border-radius: 9px;
  padding: 1rem 2rem;
  font-size: 22px;
  letter-spacing: 0.11em;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  display: inline-block;
}

.register-text a:hover {
  color: #7a7a7a;
}

.register-text a {
  color: darkgray;
  text-decoration: underline;
}

.register-text {
  display: inline-block;
  margin-left: 1rem;
  color: darkgray;
}

.cta-block {
  padding: 0 0 0 2.5%;
}

.mobile-subheading {
  font-weight: 200;
  font-size: 16px;
  color: gray;
}

@media (max-width: 1200px) {
  .video-wrapper {
    width: 100%;
    margin: 0;
    padding-bottom: 56.25%;
  }
  .stripe-wrapper {
    padding-top: 8%;
  }
}

@media (max-width: 500px) {
  .stripe-wrapper {
    margin: 3rem auto;
  }
  .heading-block {
    margin: 3rem auto;
  }

  h1 {
    font-size: 27px;
    text-align: center;
  }

  h2 {
    font-size: 20px;
    text-align: center;
  }
  .heading-logo {
    display: block;
    margin: 1rem auto;
  }

  .main-cta-button {
    font-size: 18px;
    display: block;
    margin: 1rem auto;
  }

  .register-text {
    text-align: center;
  }
}
</style>
